<template>
  <div class="login-component">
    <div class="image-container"></div>
    <transition appear name="fadeRight" mode="out-in">
        <form @submit.prevent="validateForm" id="create-spieler-form" class="login-form black-background">
          <div class="container">
            <h2>{{lang.createAccount.headline}}</h2>
            <div class="md-fields-comtainer">
              <md-field class="md-field-icon" :class="getValidationClass('vorname')">
                <i class="fas fa-user-alt"></i>
                <label>{{lang.spielerForm.labelVorname}}</label>
                <md-input @blur="$v.vorname.$touch()" :name="lang.spielerForm.labelVorname" id="vorname" v-model="vorname" autocomplete="given-name" type="text"></md-input>
                <md-icon v-if="isFieldInvalid('vorname')" class="color-invalid">close</md-icon>
                <md-icon v-if="!$v.vorname.$invalid && submitClicked" class="color-valid">check</md-icon>
                <span class="md-error" v-if="!$v.vorname.required">{{lang.spielerForm.vornameRequired}}</span>
                <span class="md-error" v-else-if="!$v.vorname.minLength">{{$v.vorname.$params.minLength.min}} {{lang.spielerForm.vornameMinLength}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('nachname')">
                <i class="fas fa-user-alt"></i>
                <label>{{lang.spielerForm.labelNachname}}</label>
                <md-input @blur="$v.nachname.$touch()" :name="lang.spielerForm.labelNachname" id="nachname" v-model="nachname" autocomplete="family-name" type="text"></md-input>
                <md-icon v-if="isFieldInvalid('nachname')" class="color-invalid">close</md-icon>
                <md-icon v-if="!$v.nachname.$invalid && submitClicked" class="color-valid">check</md-icon>
                <span class="md-error" v-if="!$v.nachname.required">{{lang.spielerForm.nachnameRequired}}</span>
                <span class="md-error" v-else-if="!$v.nachname.minLength">{{$v.nachname.$params.minLength.min}} {{lang.spielerForm.nachnameMinLength}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('email')">
                <i class="fas fa-envelope"></i>
                <label>{{lang.spielerForm.labelEMail}}</label>
                <md-input @blur="$v.email.$touch()" :name="lang.spielerForm.labelEMail" id="email" v-model.trim="email" autocomplete="email" type="email"></md-input>
                <md-icon v-if="isFieldInvalid('email')" class="color-invalid">close</md-icon>
                <md-icon v-if="!$v.email.$invalid && submitClicked" class="color-valid">check</md-icon>
                <span class="md-error" v-if="!$v.email.required">{{lang.spielerForm.emailRequired}}</span>
                <span class="md-error" v-else-if="!$v.email.email">{{lang.spielerForm.emailRequired}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('passwort')">
                <i class="fas fa-unlock"></i>
                <label>{{lang.spielerForm.labelPasswort}}</label>
                <md-input @blur="$v.passwort.$touch()" :name="lang.spielerForm.labelPasswort" id="passwort" v-model="passwort" autocomplete="new-password" type="password"></md-input>
                <span class="md-error" v-if="!$v.passwort.required">{{lang.spielerForm.passwortRequired}}</span>
                <span class="md-error" v-else-if="!$v.passwort.minLength">{{$v.passwort.$params.minLength.min}} {{lang.spielerForm.passwortMinLength}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="$v.passwortConfirm.$invalid && $v.passwortConfirm.$dirty ? 'md-invalid' : ''">
                <i class="fas fa-unlock"></i>
                <label>{{lang.spielerForm.labelPasswortRepeat}}</label>
                <md-input @blur="$v.passwortConfirm.$touch()" :name="lang.spielerForm.labelPasswortRepeat" id="passwortConfirm" v-model="passwortConfirm" autocomplete="new-password" type="password"></md-input>
                <span class="md-error" v-if="!$v.passwortConfirm.sameAsPassword">{{lang.spielerForm.passwortConfirm}}</span>
              </md-field>
              <md-checkbox :name="lang.spielerForm.labelDatenschutzConfirm2" v-model="datenschutzConfirm" class="md-primary">
              {{lang.spielerForm.labelDatenschutzConfirm1}} <router-link :to="{ name: 'Datenschutz' }">{{lang.spielerForm.labelDatenschutzConfirm2}}</router-link> {{lang.spielerForm.labelDatenschutzConfirm3}}
              </md-checkbox>
            </div>
            <vue-hcaptcha 
              sitekey="74d4ee3c-859f-40e6-8e1e-b80c9c91fb6a" 
              :language="lang.name"
              @verify="onVerify"
              @expired="onExpire"
              @error="onError"
            ></vue-hcaptcha>
            <div class="button-list">
              <md-button type="submit" class="md-primary md-round md-raised md-center md-filled">
                {{lang.createAccount.buttonText1}}
              </md-button>
              <a @click="$router.go(-1)">
                <md-button class="md-primary md-round md-raised md-center md-border">
                  {{lang.createAccount.buttonText2}}
                </md-button>
              </a>
            </div>
          </div>
        </form>
    </transition>
    <SubmitModal/>
    <InformationModal/>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import SubmitModal from '../Utils/SubmitModal'
import InformationModal from '../Utils/InformationModal'
import {required, minLength, email, sameAs} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: 'CreateAccountForm',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    InformationModal,
    VueHcaptcha
  },
  data() {
    return {
      nachname: "",
      vorname: "",
      email: "",
      passwort: "",
      passwortConfirm: "",
      datenschutzConfirm: false,
      submitClicked: false,
      captcha: {
        verified: false,
        expired: false,
        token: null,
        eKey: null,
        error: null,
      }
    }
  },
  validations: {
    nachname: {
      required,
      minLength: minLength(2)
    },
    vorname: {
      required,
      minLength: minLength(2)
    },
    email: {
      required,
      email
    },
    passwort: {
      required,
      minLength: minLength(5)
    },
    passwortConfirm: {
      sameAsPassword: sameAs('passwort')
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    }
  },
  methods: {
    onVerify(token, ekey) {
      this.captcha.verified = true;
      this.captcha.token = token;
      this.captcha.eKey = ekey;
    },
    onExpire() {
      this.captcha.verified = false;
      this.captcha.token = null;
      this.captcha.eKey = null;
      this.captcha.expired = true;
      this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.captcha.expired);
    },
    onError(err) {
      this.captcha.token = null;
      this.captcha.eKey = null;
      this.captcha.error = err;
      this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.captcha.error);
    },
    validateForm() {
      this.$v.$touch();
      this.submitClicked = true;

      if (!this.datenschutzConfirm) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.spielerForm.datenschutzConfirmRequired);
      }

      if (!this.captcha.verified) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.captcha.required);
      }

      if (!this.$v.$invalid && this.captcha.verified && this.datenschutzConfirm) {
        this.addSpieler();
      }
    },
    async addSpieler() {
      const newSpieler = {
        nachname: this.nachname,
        vorname: this.vorname,
        email: this.email,
        passwort: this.passwort,
      }
      const spielerData = {
        newSpieler: newSpieler,
        token: this.captcha.token,
        datenschutzConfirm: this.datenschutzConfirm
      }

      if (this.isOffline) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
      } else {
        await store.dispatch('postSpieler', spielerData)
          .then(result => {
            if (result.data != null) {
              this.showDialogActive(true, this.lang.createAccount.successHeadline, this.lang.createAccount.successMessage);
              setTimeout(() => { 
                store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false);
                router.push({name: "Login"});
              }, 3500);
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
            }
          })
          .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
          });
      }
    },
    getValidationClass(fieldName) {
      return {
        'md-invalid': this.isFieldInvalid(fieldName)
      }
    },
    isFieldInvalid(fieldName) {
      const field = this.$v[fieldName]
      if (field) {
        return field.$invalid && field.$dirty;
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/base';
.image-container {
  background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-5.jpg");
  background-position: 0% -3%;
}

.login-form {
  .button-list {
    margin-top: 20px;
  }
}

@media (min-width: $screen-xs2) {
  .image-container {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-5.jpg");
  }
}

@media (max-width: $screen-xs2) {
  .md-checkbox {
    margin: 5px 0 30px;
  }
}

@media (min-width: $screen-sm) {
  .md-field {
    margin: 4px 0 18px;
  }

  .md-checkbox {
    margin: 5px 0 20px;
  }

  .login-component {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-5.jpg");
  }
  
  .login-form {
    .container {
      padding: 70px 50px 10px;
    }
    .button-list {
      margin-top: 20px;
    }
  }
}

@media (min-width: $screen-lg) {
  .login-component {
    background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-5.jpg");
  }
}
</style>
