<template>
    <div>
        <CreateAccountForm />
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import CreateAccountForm from '@/components/Login/CreateAccountForm.vue'
import store from '@/store/index'

export default {
  name: 'Login',
  components: {
    CreateAccountForm
  },
  computed: {
    loadingStatus() {
      return store.state.spieler.loadingStatus;
    },
  },
}
</script>
